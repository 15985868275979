@import './reset.scss';
@import './404.scss';
@import './colors';
@import './typography';
@import './breakpoints';
@import './layout';
@import './theme';

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family-1;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
}

#hubspot-messages-iframe-container {
  z-index: 10 !important;
}

//
// Typography defaults
//
h1 {
  @apply text-h2 lg:text-h1 font-normal;
  line-height: 1.2em;
}

h2 {
  @apply text-h3 lg:text-h2 font-medium;
  line-height: 1.2em;
}

h3 {
  @apply text-h4 lg:text-h3 font-medium;
  line-height: 1.2em;
}

h4 {
  @apply text-h5 lg:text-h4 font-medium;
  line-height: 1.2em;
}

h5 {
  @apply text-h5 lg:text-h5 font-medium;
  line-height: 1.2em;
}

h6 {
  @apply text-sm font-medium;
  line-height: 1.2em;
}

p {
  @apply text-sm lg:text-md;
  line-height: 1.5em;
}

b,
strong {
  @apply font-medium;
}

blockquote {
  @include type_heading_3;
}

.no-scroll {
  overflow: hidden;
}

.aspect-square {
  position: relative;
}

.aspect-square::before {
  float: left;
  padding-top: 100%;
  content: '';
}

.aspect-square::after {
  display: block;
  content: '';
  clear: both;
}

.aspect-video {
  position: relative;
}

.aspect-video::before {
  float: left;
  padding-top: 56.25%;
  content: '';
}

.aspect-video::after {
  display: block;
  content: '';
  clear: both;
}

.wg-default,
.wg-default .country-selector {
  left: 1rem;
  right: unset !important;
  bottom: 1rem !important;
  border-radius: 0.4rem;
  border: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

aside.weglot_switcher.wg-drop.wg-mouse-click.country-selector {
  border-radius: 0.4rem;
}

.wg-drop.country-selector .wgcurrent {
  border: 0 !important;
}

.wg-drop.country-selector .wgcurrent:after {
  display: none !important;
}

.wg-drop.country-selector .wgcurrent a {
  padding: 0 !important;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wg-flags a img.wg-flag {
  margin: 0 !important;
}

.wg-drop.country-selector {
  background-color: transparent !important;
}

.wg-drop.country-selector:hover {
  background-color: rgba(100, 100, 100, 0.5) !important;
}

.wg-drop.country-selector a {
  height: 40px;
  padding: 0 !important;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
}

.wg-drop.country-selector ul {
  border-radius: 0.4rem;
  border: 0 !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
