.sanity-error-page {
  background: var(--white);
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sanity-error-page div {
  margin: 2rem 1rem 4rem 1rem;
}

.sanity-error-page img {
  display: block;
  max-width: 30rem;
  width: 100%;
  height: auto;
}
