@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }
}
